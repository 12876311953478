<template>
  <tr :class="{ 'chat-group-members__row-admin': chat.profile_id == 2 }">
    <WiListDataTableActions
      :config="config"
      :props="props"
      :deleteIcon="'person_remove'"
    ></WiListDataTableActions>
    <td class="justify-center">
      <div class="chat-group-members__name-container">
        <v-avatar size="40">
          <img v-if="chat.person.file && chat.person.file.url" :src="chat.person.file.url">
          <img v-else src="static/icons/user_icon_1.png" />
        </v-avatar>
        <span class="chat-group-members__name">
          <span class="ml-2" style="font-size: 16px;">
            {{ chat.person.name }}
          </span>
          <span v-if="false" class="ml-2" style="font-size: 12px; font-weight: 100;">
            <!-- Online -->
          </span>
        </span>
      </div>
    </td>
    <td class="justify-left">
      <span v-if="chat.profile_id == 1" class="chat-group-members__status chat-group-members__status--active">
        Membro
      </span>
      <span v-else-if="chat.profile_id == 2" class="chat-group-members__status chat-group-members__status--admin">
        Administrador
      </span>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'ChatGroupMembers',
    computed: {
      chat () {
        return this.props.item
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.chat-group-members__status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.chat-group-members__status--active {
  color: #eceff1;
  background: rgb(4, 112, 0);
  border-radius: 3px;
}
.chat-group-members__status--admin {
  color: #eceff1;
  background: #000000;
  border-radius: 3px;
}
.chat-group-members__name-container {
  display: flex;
  align-items: center;
}
.chat-group-members__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>